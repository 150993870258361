<template>
  <div>
    <!-- <pre>{{ booking }}</pre> -->
    <div
      class="flex-row justify-content-between p-3 card d-none d-sm-flex"
      :class="{
        'bg-disable':
          booking.payment_status === 'BANK_TRANSFER_REJECTED' ||
          booking.payment_status === 'ONLINE_BANKING_REJECTED',
      }"
    >
      <div class="d-flex align-items-center">
        <!-- <b-img :src="booking.maid.user.image_url" class="avatar mr-4" @click.stop="showMaidDetail" /> -->
        <!-- @click.stop="showMaidDetail" -->
        <div >
        <MaidAvatar
          :url="booking.maid.user.image_url"
          :level="booking.maid.level"
          class="mr-3"
        />
        </div>
        <div class="d-inline-block">
          <div class="flex-wrap">
          <span>{{ durationLabel }}</span>
          <BookingStatus  :booking="booking" />
          </div>
          <span
            >{{ booking.maid.user.nick_name ? `"${booking.maid.user.nick_name}" ` : ``
            }}{{ `${booking.maid.user.first_name} ${booking.maid.user.last_name}` }}</span
          >
          <p
            v-if="
              booking.payment_status === 'BANK_TRANSFER_REJECTED' ||
              booking.payment_status === 'ONLINE_BANKING_REJECTED'
            "
            class="mb-0 text-danger text-xl"
          >
            {{ $t('payment_history.please_contract') }}
          </p>

          <br v-else />
          <span class="text-primary">{{ services }}</span
          >
        </div>
      </div>
      <div class="d-flex flex-column justify-content-between align-items-end">
        <b-img :src="booking.icon" class="mb-4" />
        <div style="display: flex; align-items: center;" v-if="canRating" >
          <!-- <span class="circle-badge mr-2 flex-shrink-0" v-if="!isMoreThanThirtyDaysAgo && showRedDots"></span> -->
          <b-button
            @click.stop="chooseMaidToReview(booking)"
            class="px-4 d-none d-sm-block"
            variant="primary"
          >{{ $t('button.rate_maid') }}
            <span
              class="circle-badge ml-2"
              v-if="!isMoreThanThirtyDaysAgo && showRedDots"
              style="margin-bottom: 3px;"
            ></span>
          </b-button
          >
          <b-img
            src="../assets/images/information-icon-gray.svg"
            v-b-tooltip.hover
            :title="$t('rating.i_message')"
            @click.stop class="ml-2"
          ></b-img>
        </div>
        <div class="d-none d-sm-inline-block text-right" v-if="canViewOrChange">
          <span>{{ ratingLabel }}</span>
          <br />

          <b-button @click.stop="chooseMaidToReview(booking)" variant="link">{{
            buttonText
          }}</b-button>
        </div>
        <br />
        <div class="click-text">{{ $t('payment_history.click_text') }}</div>
      </div>
    </div>
    <div
      class="card py-3 d-block d-sm-none"
      :class="{
        'bg-disable':
          booking.payment_status === 'BANK_TRANSFER_REJECTED' ||
          booking.payment_status === 'ONLINE_BANKING_REJECTED',
      }"
    >
      <div class="d-flex flex-row justify-content-between px-3 w-full">
        <div class="d-flex align-items-center text-sm size_08 w-full">
          <!-- <b-img :src="booking.maid.user.image_url" class="avatar mr-4" @click.stop="showMaidDetail" /> -->
          <!-- @click.stop="showMaidDetail" -->
           <div class="flex-column align-items-center-full">
          <b-img :src="booking.icon"  class="size_xs mr-2"/>
          <div>
          <MaidAvatar
            :url="booking.maid.user.image_url"
            :level="booking.maid.level"
            class="mr-2 "
            size="xs"
          />
        </div>
          </div>
          <div class="d-inline-block w-full">
            <div class="flex-wrap-1 size_08">
            <span class="text-nowrap size_08">{{ durationLabel }}</span>
            <BookingStatus  :booking="booking" />
            </div>
            <span
              >{{ booking.maid.user.nick_name ? `"${booking.maid.user.nick_name}" ` : ``
              }}{{ `${booking.maid.user.first_name} ${booking.maid.user.last_name}` }}</span
            >
            <br />
            <p
              v-if="
                booking.payment_status === 'BANK_TRANSFER_REJECTED' ||
                booking.payment_status === 'ONLINE_BANKING_REJECTED'
              "
              class="mb-0 text-danger text-xl"
            >
              {{ $t('payment_history.please_contract') }}
            </p>

            <div v-else />
            <span class="text-primary">{{ services }}</span
            ><div />
            <span class="size_08">{{ ratingLabel }}</span>
            <div class="justify-end">
            <div class="click-text">{{ $t('payment_history.click_text') }}</div>
            </div>
          </div>
        </div>
        <!-- <div class="d-flex flex-column justify-content-between align-items-end">
          <b-img :src="booking.icon" class="mb-4" />
          <div class="click-text">{{ $t('payment_history.click_text') }}</div>
        </div> -->
      </div>

      <b-row class="justify-content-center mt-2" v-if="canRating">
        <div class="w-50 position-relative">
          <b-button
            @click.stop="chooseMaidToReview(booking)"
            class="d-block size_08 w-100"
            variant="primary"
          >
            {{ $t('button.rate_maid') }}
            <span
              class="circle-badge ml-2"
              v-if="!isMoreThanThirtyDaysAgo && showRedDots"
              style="margin-bottom: 3px;"
            ></span>
          </b-button>
          <b-img
            src="../assets/images/information-icon-gray.svg"
            v-if="!isMoreThanThirtyDaysAgo" v-b-tooltip.hover
            :title="$t('rating.i_message')"
            @click.stop class="middle-right"
          ></b-img>
        </div>
      </b-row>
      <b-row class="d-block text-center size_08" v-if="canViewOrChange">
        <br />
        <b-button @click.stop="chooseMaidToReview(booking)" class="size_08" variant="link">{{
          buttonText
        }}</b-button>
      </b-row>
    </div>
  </div>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator'
import Constant, { STATUS } from '../config/booking'
import moment from 'moment'
import BookingStatus from './BookingStatus'
import MaidAvatar from '../components/MaidAvatar'

@Component({
  props: {
    booking: Object,
    showRatingBtn: false,
    showRedDots: false,
  },
  components: { BookingStatus, MaidAvatar },
})
export default class BookingItem extends Vue {
  get shouldShowBadge() {
    return !this.isUsed
  }

  get buttonText() {
    if (this.isMoreThanThirtyDaysAgo || this.maid_review.rating == 5) {
      return this.$t('button.view_rating')
    }
    return !!this.maid_review ? this.$t('button.change_rating') : this.$t('button.view_rating')
  }

  get canRating() {
    console.log('this ShowRating >>>>', this.showRatingBtn)
    console.log('this Maid_review >>>>', this.maid_review)
    console.log('this morethan 30 days >>>>', this.isMoreThanThirtyDaysAgo)
    console.log('isUsed >>>>', this.isUsed)
    console.log('isApproved >>>>', this.isApproved)

    return (
      this.showRatingBtn &&
      !this.maid_review &&
      !this.isMoreThanThirtyDaysAgo &&
      this.isUsed &&
      this.isApproved
    )
  }

  get canViewOrChange() {
    return !!this.maid_review && this.isApproved
  }

  get maid_review() {
    console.log('booking maid_review', this.booking.maid_review)
    return this.booking.maid_review
  }

  get ratingLabel() {
    if (!this.maid_review) return ``
    const label = [
      ' ',
      this.$t('rating.not_satisfied_at_all'),
      this.$t('rating.not_satisfied'),
      this.$t('rating.neutral'),
      this.$t('rating.satisfied'),
      this.$t('rating.very_satisfied'),
    ][this.maid_review.rating]
    return `${this.$t('alias.rating')}: ${label}`
  }

  chooseMaidToReview(booking) {
    this.$emit('chooseMaidToReview', booking)
  }

  get services() {
    const services = this.booking.services.map((service) => {
      const serviceLower = service.toLowerCase()
      return this.$t(`services.${serviceLower}`)
    })
    return services.join(' / ')
  }

  get isCanceled() {
    return this.booking.status === STATUS.canceledByCustomer
  }

  get isApproved() {
    return this.booking.status === STATUS.approved
  }

  // เช็คว่าจ่ายเงินเกิน 1 วันแล้วหรือยัง ?
  // หากเกิน 1 วันแล้วไม่มีการตอบรับ ลูกค้าจะต้องเปลี่ยนวันเวลา
  // Fix me - status should be calculate from backend
  // Remove if not used
  get isTimeoutOfAppproval() {
    if (!this.booking.payment) return false
    const isTimeoutOfAppproval =
      moment().diff(moment(this.booking.payment.create_datetime), 'seconds') > 60 * 60 * 24
    return isTimeoutOfAppproval
  }

  get isNoAvailableMaid() {
    return this.booking.status === STATUS.noAvailableMaid
  }

  // // Booking Unsuccessful
  get isBookingUnSuccessful() {
    if (this.isNoAvailableMaid) return true

    // Manual Select
    if (!!this.booking.raw.booking.maid) {
      return this.booking.status === STATUS.canceledByMaid
    }

    // Auto select
    // Fix me - remove this condition when backend not response payment object
    if (this.booking.payment) {
      return this.booking.status === STATUS.awaiting && this.isTimeoutOfAppproval
    }

    return this.booking.status === STATUS.canceledByMaid
  }
  get isReview() {
    if (this.booking.payment_status === 'BANK_TRANSFER_REVIEWING') {
      return true
    }
    return false
  }

  get badgeLabel() {
    if (this.isReview) {
      return this.$t('status.bank_transfer_reviewing')
    }
    if (this.isBookingUnSuccessful) {
      if (this.isBookingMP) {
        return this.$t('status.assignment_unsuccessful')
      } else {
        return this.$t('status.booking_unsuccessful')
      }
    }

    switch (this.booking.status) {
      case STATUS.canceledByCustomer:
        return this.$t('status.canceled')
      case STATUS.approved:
        return this.$t('status.booking_confirmed')
      case STATUS.awaiting:
        return this.$t('status.payment_confirmed')
      case STATUS.updateAwaiting:
        return this.$t('status.waiting_for_maids_confirmation')
      default:
        return ''
    }
  }

  get badgeVaraint() {
    if (this.isReview) {
      return `light`
    }
    if (this.isBookingUnSuccessful) return `danger`
    switch (this.booking.status) {
      case STATUS.canceledByCustomer:
        return `danger`
      case STATUS.approved:
        return this.isBookingMP ? `warning` : `success`
      case STATUS.awaiting:
        return `primary`
      case STATUS.updateAwaiting:
        return `danger`
      default:
        return ''
    }
  }

  get isServiceCompleted() {
    return moment() > moment(this.booking.end_datetime) && this.booking.status === STATUS.approved
  }

  get isBookingMP() {
    return this.booking.type === Constant.MULTI_PACKAGE
  }

  get isMobileScreen() {
    return parseInt(screen.width) <= 425
  }

  get durationLabel() {
    // console.log("this.booking", this.booking);
    console.log(this.$i18n.locale)
    const dateFormat = this.$i18n.locale === 'th' ? `DD MMM YYYY` : `MMM DD, YYYY`
    const durationHrs = moment(this.booking.end_datetime).diff(this.booking.start_datetime, 'hour')
    const startDate = moment(this.booking.start_datetime).format(dateFormat)
    const startHr = this.$momentTZ(this.booking.start_datetime).format('H:mm')
    const startIn = moment(this.booking.start_datetime).fromNow()
    return `${startDate}, ${startHr} (${durationHrs} ${this.$t('alias.hrs')}), ${startIn}`
  }

  get isMoreThanThirtyDaysAgo() {
    return moment().diff(moment(this.booking.start_datetime), 'day') > 30
  }

  get isUsed() {
    console.log(
      'date comparison',
      this.$momentTZ(new Date()) >= new Date(this.booking.end_datetime)
    )
    // return moment(new Date()) >= moment(this.booking.end_datetime)
    return this.$momentTZ(new Date()) >= new Date(this.booking.end_datetime)
  }

  showMaidDetail() {
    // Prevent to set maid detail if no maid for the booking (Auto select and still waiting for approval)
    if (!!this.booking.raw.booking.maid) {
      this.$store.dispatch('maid/setShowMaidModal', this.booking.raw.booking.maid)
      this.$root.$emit('bv::show::modal', 'maid-modal')
    }else{
      this.$emit('clickBooking')
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  width: 64px !important;
  height: 64px !important;
  border-radius: 64px;
  @media (max-width: 767px) {
    width: 48px !important;
    height: 48px !important;
  }
}
.nav-link {
  &.active {
    border-bottom-color: #005dff !important;
  }
}
</style>
<style lang="scss">
.size_08 {
  font-size: 0.8rem !important;
}
.size_09 {
  font-size: 0.9rem !important;
}
.bg-disable {
  background: #f5f5f5;
}
.circle-badge {
  display: inline-table;
  vertical-align: middle;
  width: 10px;
  height: 10px;
  background-color: #ff7070;
  border-radius: 50%;
}
.middle-right {
  position: absolute;
  top: 50%;
  right: -10%;
  transform: translate(50%, -50%);
}
.tooltip-inner {
  font-size: 16px;
}
@media (max-width: 767px) {
  .tooltip-inner {
    font-size: 14px;
  }

  .click-text {
    font-size: 0.5rem;
  }
}

.click-text {
  color:#a3a3a3;
  font-size: 0.8rem;
  white-space: nowrap;
}

.flex-wrap{
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-bottom: 3px;
}

.flex-wrap-1{
  display: flex;
  flex-wrap: wrap;
}

.align-items-center-full{
  width : 100%;
  height: 100%;
  gap : 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.justify-end{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.w-full{
  width: 100%;
}

.xs{
  width: 40px !important;
  height: 40px !important;
  border-radius: 40px;
}

.size_xs{
  width: 20px !important;
  height: 20px !important;
}

@media screen and (min-width: 375px) { 
  .size_xs{
    width: 25px !important;
    height: 25px !important;
  }
  .xs{
    width: 64px !important;
    height: 64px !important;
    border-radius: 64px;
  }
}
</style>